import React from 'react';
import HowImportSection from '../../components/how-import-section';

const HowImport = () => {

    return (
        <div id='why-import'>
            <HowImportSection />
        </div>
    );
};
export default HowImport;