import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import importImage from '../assets/images/import/import-min.jpg';
import whyImportImage from '../assets/images/import/porsche-min.png';
import whyImportImageRight from '../assets/images/import/why-import-right.jpg';
import howImport01 from '../assets/images/import/how-import-01.jpg';
import howImport02 from '../assets/images/import/how-import-02.jpg';
import howImport03 from '../assets/images/import/how-import-03.jpg';
import howImport04 from '../assets/images/import/how-import-04.jpg';
import howImport05 from '../assets/images/import/how-import-05.jpg';
import howImport06 from '../assets/images/import/how-import-06.jpg';
import whyImportImageLeft from '../assets/images/import/why-import-left.jpg';


const WhyImportSection = () => {
    return (
    <div id="why-import-section">
     <Container>
      <Row>
        <h1 className='import-title'>Comment faire de l'import automobile ?
        </h1>
      </Row>

      <Row className='how-import-row'>
        <Col md={8}>
        <Row>
          <div className='how-import-subsection'>
            <h1>01</h1>
            <h2>Trouvez votre véhicule</h2>
            <p>
            Nous mettons à disposition notre moteur de recherche personnalisé afin de trouver la voiture de vos rêves. Nous pouvons également réaliser une recherche personnalisée pour vous.
            </p>
          </div>
        </Row>
        </Col>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={howImport01} className="how-import-img" />
          </div>
        </Col>
      </Row>

      <Row className='how-import-row'>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={howImport02} className="how-import-img" />
          </div>
        </Col>
        <Col md={8}>
        <Row>
          <div className='how-import-subsection'>
            <h1>02</h1>
            <h2>Collecte et vérification de l’historique et des documents du véhicule</h2>
            <p>
              Nous nous rapprochons du garage vendeur du véhicule afin de collecter et inspecter les documents du véhicule. Nous nous renseignons également sur l’état général de la voiture ainsi que toute information complémentaire souhaitée.
            </p>
          </div>
        </Row>
        </Col>
      </Row>

      <Row className='how-import-row'>
        <Col md={8}>
        <Row>
          <div className='how-import-subsection'>
            <h1>03</h1>
            <h2>Négociation du prix</h2>
            <p>
            En tant que professionnels, nous sommes en mesure de négocier le prix et les conditions de vente avec le garage vendeur. Vous serez l’unique bénéficiaire de cette baisse de prix.
            </p>
          </div>
        </Row>
        </Col>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={howImport03} className="how-import-img" />
          </div>
        </Col>
      </Row>

      <Row className='how-import-row'>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={howImport04} className="how-import-img" />
          </div>
        </Col>
        <Col md={8}>
        <Row>
          <div className='how-import-subsection'>
            <h1>04</h1>
            <h2>Signature du bon de commande et règlement</h2>
            <p>
            Une fois toutes les informations portées à votre attention, c’est maintenant a vous de jouer en signant le bon de commande pour l’achat du véhicule. A noter qu’avant toute signature du bon de commande, nous pouvons également vous organiser une visite avec le garage vendeur.
            </p>
          </div>
        </Row>
        </Col>
      </Row>

      <Row className='how-import-row'>
        <Col md={8}>
        <Row>
          <div className='how-import-subsection'>
            <h1>05</h1>
            <h2>Gestion administrative</h2>
            <p>
            Une fois le bon de commande signé, nous lançons de notre côté l’ensemble de la gestion administrative qui vous permettra de rouler avec le véhicule importé en France.
            </p>
          </div>
        </Row>
        </Col>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={howImport05} className="how-import-img" />
          </div>
        </Col>
      </Row>

      <Row className='how-import-row'>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={howImport06} className="how-import-img" />
          </div>
        </Col>
        <Col md={8}>
        <Row>
          <div className='how-import-subsection'>
            <h1>06</h1>
            <h2>Livraison ou collecte de votre véhicule</h2>
            <p>
            Une fois votre véhicule prêt, deux possibilités s’offrent a vous : 
            Aller chercher le véhicule directement dans le garage vendeur (nous organisons la collecte)
            Se faire livrer sur plateau le véhicule directement chez vous sous un délai de 15 jours (prestation facturée a part)
            </p>
          </div>
        </Row>
        </Col>
      </Row>
        
     </Container>
    </div>
    );
    };
    export default WhyImportSection;