import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import logoImage from '../assets/images/logo.png';



const AboutSection = () => {
    return (
    <div id="about-section">
     <Container>
        <Row className="mt-1 mb-2">
          <Col xs={{span:12, order:"last"}} md={{span:6, order:"first"}}>
            <div className="image_iman">
                <img src={logoImage} className="about_img" />
            </div>
          </Col>
          <Col xs={{span:12, order:"first"}} md={{span:6, order:"last"}}>
            <div className="mt-2 mb-5">
              <h1 className="fs-2 fw-600">
                  Qui sommes nous <span className="primary-color">?</span>
              </h1>
              <p className="about-text fs-5 m-0">
                  L'agence Toulousaine Le Comptoir Auto vous accompagne dans l'achat et la vente de votre véhicule d'occasion.<br></br>

                  Que vous soyez un acheteur ou un vendeur de véhicule d'occasion, Le Comptoir Auto est votre partenaire de confiance à Toulouse. Notre expertise en dépôt-vente nous permet de vous proposer un service complet et sécurisé pour la vente de votre voiture sans vous priver de votre véhicule . Nous nous occupons de tout, de l'estimation de votre véhicule à la publication de son annonce, en passant par les visites et la négociation avec les potentiels acheteurs.
                  <br></br>
                  Vous recherchez un véhicule d'occasion particulier ? Notre réseau de partenaires nous permet de vous proposer une large sélection de voitures importées de toute l'Europe. Profitez de nos services sur-mesure pour trouver le véhicule qui correspond parfaitement à vos besoins et à votre budget.
                  <br></br>
                  Le Comptoir Auto, c'est la garantie d'une transaction simple, rapide et sécurisée pour votre véhicule d'occasion. N'hésitez pas à nous contacter pour en savoir plus!

              </p>
              <div className="mt-3">
                  <a href="#" className="readmore-btn fs-5 px-3 py-2">Contactez-nous</a>
              </div>
            </div>
          </Col>
        </Row>
     </Container>
    </div>
    );
    };
    export default AboutSection;