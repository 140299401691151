import React from 'react';
import ImportSection from '../../components/import-section';

const Import = () => {

    return (
        <div id='import'>
            <ImportSection />
        </div>
    );
};
export default Import;