import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import importImage from '../assets/images/import/import-min.jpg';
import whyImportImage from '../assets/images/import/porsche-min.png';
import whyImportImageRight from '../assets/images/import/why-import-right.jpg';
import whyImportImageLeft from '../assets/images/import/why-import-left.jpg';


const WhyImportSection = () => {
    return (
    <div id="why-import-section">
     <Container>
      <Row>
        <h1 className='import-title'>Pourquoi faire de l'import automobile ?
        </h1>
      </Row>

      <Row className='why-import-row'>
        <Col>
        <Row>
          <div className='why-import-subsection bg-yellow'>
            <h2>Un marché riche et diversifié</h2>
            <p>
            En important votre véhicule, vous accédez à un marché beaucoup plus large qu'en France. Vous avez ainsi le choix parmi une multitude de marques, de modèles et de finitions que vous ne trouverez peut-être pas sur le marché français. C'est l'occasion idéale pour les passionnés de trouver la voiture de leurs rêves, qu'il s'agisse d'un modèle rare ou d'une version introuvable en France.
            </p>
          </div>
        </Row>
        <Row>
          <div className='why-import-subsection bg-theme-color-light'>
            <h2>Des véhicules mieux équipés</h2>
            <p>
            Les constructeurs automobiles proposent souvent des options et des finitions différentes selon les pays. En important votre véhicule d'Allemagne, par exemple, vous pouvez profiter de voitures mieux équipées, avec des options que vous ne trouverez pas sur les modèles français.
            </p>
          </div>
        </Row>
        </Col>
        <Col>
          <div className="">
            <img src={whyImportImageRight} className="why-import-img" />
          </div>
        </Col>
      </Row>

      <Row className='why-import-row'>
        <Col>
          <div className="">
            <img src={whyImportImageLeft} className="why-import-img" />
          </div>
        </Col>
        <Col>
        <Row>
          <div className='why-import-subsection bg-yellow'>
            <h2>Des prix plus attractifs</h2>
            <p>
            Les prix des véhicules d'occasion sont souvent plus bas dans d'autres pays qu'en France. Cela s'explique par plusieurs facteurs, tels que la fiscalité, le taux de change et la différence de demande. En important votre voiture, vous pouvez ainsi réaliser des économies considérables.
            </p>
          </div>
        </Row>
        <Row>
          <div className='why-import-subsection bg-theme-color-light'>
            <h2>Des véhicules entretenus avec soin</h2>
            <p>
            Les automobilistes allemands sont réputés pour être particulièrement soigneux avec leurs véhicules. Ils effectuent régulièrement l'entretien de leur voiture et utilisent des produits de qualité. Vous avez donc plus de chances de trouver une voiture d'occasion en Allemagne qui est en excellent état mécanique.
            </p>
          </div>
        </Row>
        </Col>
      </Row>
        
     </Container>
    </div>
    );
    };
    export default WhyImportSection;