import { useState } from "react";
import { useDispatch } from "react-redux";
import { db, auth } from "../config/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { clearUserData, setUser } from "../redux/features/UserSlice";
import { useSignIn } from "react-auth-kit";

const useAuthentication = () => {

    // const signIn = useSignIn();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const signInCall = async ({email, password}) => {

        setIsLoading (true);
        try {

            const response = await fetch(`http://localhost:5000/users/login`,
                {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password
                    }),
                }
            )

            // console.log(response.json());

            // signIn({
            //     token: response.data.token,
            //      expiresIn: 3600,
            //      tokenType: "Bearer",
            //      authState: {email : email}
            // })

            // const {user} = await signInWithEmailAndPassword(auth,
            //     email,
            //     password
            // );

            // const q = query(collection(db, "users"), where("userUID", "==", user.uid));
            // const querySnapshot = await getDocs(q);
            // const docData = querySnapshot.docs[0].data();

            // const userData = {...user, ...docData};

            // dispatch(setUser(userData));

            setMessage({
                content: "You are successfully logged in!",
                isError: false
            });
        }
        catch (err) {
            console.log(err);

            setMessage({
                content: "Incorrect mail or password, please try again!",
                isError: true
            });

        } finally {
            setIsLoading (false);
        }
    };

    const signOutCall = async () => {

        setIsLoading (true);

        try {
            await signOut (auth);
            dispatch(clearUserData());

            setMessage({
                content: "You are successfully logged out!",
                isError: false
            });
        }
        catch (err) {
            console.log(err);

            setMessage({
                content: err,
                isError: true
            });
        }
        finally {
            setIsLoading (false);
        }
    };

    return {isLoading, message, signInCall, signOutCall};
}

export default useAuthentication;