import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Button} from "react-bootstrap";
import {vehiclesData} from "../DATA/data.jsx";
import {Link} from "react-router-dom";

import ServicesCard from '../components/services-card';
import ServicesImage from '../assets/images/services-image.jpg'
import DepotImage from '../assets/images/services/depot.svg'
import ImportImage from '../assets/images/services/import.svg'
import ConseilImage from '../assets/images/services/conseils.svg'

import 'react-lazy-load-image-component/src/effects/blur.css';

const Services = () => {

    const [isLoading, setIsLoading] = useState(false);

    return (
        <div id="services" style={{clear: "both"}}>
            <Container className="py-4">
                <Row>
                    <div className='services-container'>
                        <img src={ServicesImage} className="services-image" />
                        <div className='services-imgtext'>
                            <h1 className='services-title'>Nos services</h1>
                            <h3 className='services-subtitle'>Nous proposons un ensemble de services complet, afin que vous partiez avec votre véhicule l'esprit tranquille !</h3>
                        </div>

                        <div className='services-cards-container'>
                            <Row>
                                <div className='col-sm-12 col-md-4'>
                                    <ServicesCard
                                        title="Dépôt-vente"
                                        img={DepotImage}
                                        to=""
                                    />
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <ServicesCard
                                        title="Import"
                                        img={ImportImage}
                                        to="/import"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <ServicesCard
                                        title="Conseils achat/vente"
                                        img={ConseilImage}
                                        to=""
                                    />
                                </div>
                            </Row>
                            {/* <Row className="services-button-container">
                                <button type="button" class="btn btn-primary primary-bg-color border-0 rounded-1 px-4 fw-bold">Tous nos services</button>
                            </Row> */}

                        </div>
                        
                    </div>
                    
                </Row>
            </Container>
        </div>
    );
};
export default Services;