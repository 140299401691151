import React from 'react';
import WhyImportSection from '../../components/why-import-section';

const WhyImport = () => {

    return (
        <div id='why-import'>
            <WhyImportSection />
        </div>
    );
};
export default WhyImport;