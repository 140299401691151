import React from 'react'
import {Container, Row, Col, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import importImage from '../assets/images/import/import-min.jpg';
import whyImportImage from '../assets/images/import/porsche-min.png';
import { RiCheckboxCircleFill, RiAddCircleFill } from "react-icons/ri";


const ImportSection = () => {
    return (
    <div id="import-section">
     <Container>
      <Row>
        <h1 className='import-title'>Vous rêvez d'une voiture d'occasion haut de gamme à un prix imbattable ?</h1>
      </Row>
      <Row>
        <div className="image_bandeau_import">
          <img src={importImage} className="import-image" />
          <p>
          Le Comptoir Auto exauce votre souhait en vous proposant un service d'importation de véhicules d'Allemagne sur mesure. Nous mettons notre expertise à votre service pour vous dénicher la perle rare qui correspondra parfaitement à vos besoins et à votre budget.
          </p>
        </div>
      </Row>
        
     </Container>

     <Container fluid className='import-section'>
      <Row>
        <h1 className='import-section-title'>Envie d'en savoir <span className="primary-color">plus</span> ?</h1>
      </Row>
      <Row>
        <Col>
          <div className='more-about-import'>
            <h2>Pourquoi l'import ?</h2>
            <Row>
              <Col>
                <img src={whyImportImage}/>
              </Col>
              <Col xs={7}>
                <p>
                L'import offre de nombreux avantages pour les automobilistes à la recherche d'une voiture d'occasion de qualité.
                </p>
                <Link to={`/import/pourquoi`}>
                  <button className='green-rounded-button'>En savoir plus</button>
                </Link>
              </Col>
            </Row>
          </div>
        </Col>
        <Col>
          <div className='more-about-import'>
            <h2>Comment ça marche ?</h2>  
            <Row>
              <Col>
                <img src={whyImportImage}/>
              </Col>
              <Col xs={7}>
                <p>
                Découvrez les différentes étapes d'un import avec Le Comptoir Auto
                </p>
                <Link to={`/import/comment`}>
                  <button className='green-rounded-button'>En savoir plus</button>
                </Link>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
     </Container>

     <Container>
      <Row>
        <h1 className='import-section-title'>Notre prestation</h1>

        <div className='formule-import bg-yellow'>
          <h2>Formule import</h2>
          <h3>990€ TTC</h3>

          <div className='formule-separator'></div>

          <ul>
            <li><RiCheckboxCircleFill />Recherche personnalisée</li>
            <li><RiCheckboxCircleFill />Communication avec le garage Allemand</li>
            <li><RiCheckboxCircleFill />Vérification des documents</li>
            <li><RiCheckboxCircleFill />Conseil achat</li>
            <li><RiCheckboxCircleFill />Gestion administrative</li>
            <li><RiCheckboxCircleFill />Gestion du transport</li>
            <li><RiAddCircleFill />Extensions de garantie disponibles</li>
          </ul>

          <div className='button-container'>
            <button className='green-rounded-button'>Nous contacter</button>
          </div>

        </div>
      </Row>
     </Container>

     <Container fluid className='import-section'>
      <Row>
        <h1 className='import-section-title'>Les <span className="primary-color">derniers</span> véhicules importés</h1>
      </Row>
      <Row>
        <Col>
          <h2>Véhicule 1</h2>
        </Col>
        <Col>
          <h2>Véhicule 2</h2>
        </Col>
        <Col>
          <h2>Véhicule 3</h2>
        </Col>
      </Row>
     </Container>
    </div>
    );
    };
    export default ImportSection;